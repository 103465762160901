// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const VentureTrailersPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      ventureBunkTrailer: file(
        relativePath: { eq: "venture-bunk-trailer.jpg" }
      ) {
        ...BsmFluidImage
      }
      ventureRollerTrailer: file(
        relativePath: { eq: "venture-roller-trailer.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Venture Trailers">
      <PageTitle title="VENTURE TRAILERS" />

      <Typography paragraph>
        Bay Sails Marine carries the Venture line of trailers. We have single
        and tandem axle bunks and rollers in various sizes and load capacities.
        Additionally, we usually have pre-owned trailers on hand of various
        makes and models. You can <Link to="/directions">visit our store</Link>{" "}
        to see our selection or <Link to="/contact">contact us</Link> for more
        information on a new or used trailer.
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ImageBlock>
            <ImageWithCaption
              fluid={data.ventureBunkTrailer.childImageSharp.fluid}
              caption="Venture Bunk Trailer"
              displayCaption
              maxWidth={500}
            />
          </ImageBlock>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ImageBlock>
            <ImageWithCaption
              fluid={data.ventureRollerTrailer.childImageSharp.fluid}
              caption="Venture Roller Trailer"
              displayCaption
              maxWidth={500}
            />
          </ImageBlock>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default VentureTrailersPage
